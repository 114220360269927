import React from "react";
import { useState } from "react";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";

const AvailabilityPage = () => {
  const [data, setData] = useState({
    deliveryType: "nydalen",
    postalCode: "",
    wandaType: "DELIVERY",
  });

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 text-center text-lg font-century-gothic text-jouletext">
          Kapasitetskalender for utlevering
        </h2>
        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
        />
        <CalendarComp
          data={data}
          setData={setData}
          sourcePage={"AvailabilityPage"}
        />
      </div>
    </div>
  );
};

export default AvailabilityPage;
