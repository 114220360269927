import React from "react";
import { validateField } from "../components/formUtils";

const Field = ({
  label,
  type,
  id,
  value,
  placeholder,
  setData,
  additionalProps,
}) => {
  const handleInputChange = (event) => {
    setData((prevData) => ({ ...prevData, [id]: event.target.value }));
  };

  return (
    <>
      <label className="block text-me font-medium text-gray-700 py-2">
        {label}
      </label>
      <input
        type={type}
        id={id}
        className="block h-14 min-w-full rounded-md border border-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus-border-black"
        onChange={handleInputChange}
        onBlur={handleInputChange}
        value={value}
        placeholder={placeholder}
        {...additionalProps}
      />
      {validateField(id, value) && (
        <p className="text-gray-400">{getValidationText(id)}</p>
      )}
    </>
  );
};

const TextAreaField = ({ id, value, placeholder, setData }) => {
  const handleInputChange = (event) => {
    setData((prevData) => ({ ...prevData, [id]: event.target.value }));
  };

  return (
    <>
      <label className="block text-me font-medium text-gray-700 py-2">
        Kommentar
      </label>
      <textarea
        id={id}
        className="block resize-y h-48 min-w-full rounded-md border border-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus-border-black"
        maxLength="280"
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputChange}
        value={value}
      ></textarea>
    </>
  );
};

const getValidationText = (id) => {
  const texts = {
    serialNumber: "Tall mellom 1 og 9999",
    firstName: "Obligatorisk",
    lastName: "Obligatorisk",
    email: "Ikke gyldig e-post format.",
    number: "Ikke gyldig telefonnummer.",
  };
  return texts[id] || "";
};

const PersonaliaComp = ({ data, setData }) => {
  return (
    <div className="bg-white px-3 pt-2 pb-4 rounded-lg shadow-md mx-auto w-full sm:w-2/3 md:w-1/2">
      <div className="flex items-center space-x-4">
        {" "}
        {/* Added space-x-4 for spacing between the Field and the image */}
        <div className="flex-grow">
          <Field
            label="Elsykkelnummer"
            type="number"
            id="serialNumber"
            value={data.serialNumber}
            placeholder="Klistremerke på elsykkel"
            setData={setData}
            additionalProps={{ min: "1", max: "9999" }}
          />
        </div>
        <img
          src={process.env.PUBLIC_URL + "/pictures/joule_klistremerke.png"}
          alt="logo"
          className="object-cover w-24 h-24 rounded-lg" /* Set the width and height to make the image smaller */
        />
      </div>
      <Field
        label="Fornavn"
        type="text"
        id="firstName"
        value={data.firstName}
        setData={setData}
      />
      <Field
        label="Etternavn"
        type="text"
        id="lastName"
        value={data.lastName}
        setData={setData}
      />
      <Field
        label="E-post"
        type="email"
        id="email"
        value={data.email}
        setData={setData}
      />
      <Field
        label="Telefon"
        type="tel"
        id="number"
        value={data.number}
        setData={setData}
        additionalProps={{ pattern: "[+]{1}[0-9]{11,14}" }}
      />
      <TextAreaField
        id="note"
        value={data.note}
        placeholder="Er det noe vår mekaniker bør vite? Merk at denne informasjonen blir lest etter mottak av sykkelen. Ønsker du henting med Wanda må du sende mail til hei@joule.no"
        setData={setData}
      />
    </div>
  );
};

export default PersonaliaComp;
