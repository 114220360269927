import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import queryString from "query-string";
import axios from "axios";

const DeliveryPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate(); // Instantiate useNavigate
  const [errorMsg, setErrorMsg] = useState(""); // State for holding error messages
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    circulyOrderID: "",
    deliveryType: "",
    postalCode: "",
    wandaType: "DELIVERY",
    deliveryDate: "",
    selectedTimeslot: "",
  });

  useEffect(() => {
    const queryParams = queryString.parse(search);
    setData((currentData) => ({
      ...currentData,
      circulyOrderID: queryParams.orderID,
      deliveryType: queryParams.shippingMethod,
      postalCode: queryParams.postalCode,
    }));
  }, [search]);

  //Code that updates the count on bike movements from new orders in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.deliveryDate,
      deliveryType: data.deliveryType, // "wanda" or "nydalen"
      movementType: "delivery",
      activityType: "order",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    let timeSlotStart;
    if (data.deliveryType === "nydalen") {
      timeSlotStart = "09:00"; // Set fixed time for nydalen
    } else if (data.selectedTimeslot) {
      timeSlotStart = `${data.selectedTimeslot.from.slice(
        0,
        2
      )}:${data.selectedTimeslot.from.slice(2)}`;
    } else {
      console.error("No time slot selected");
      setIsLoading(false); // Stop loading if early return
      return;
    }

    const tagDate = `${data.deliveryDate}T${timeSlotStart}:00.000000Z`;
    console.log("Formatted tag_date:", tagDate);

    try {
      const response = await axios.post(
        `/api/circuly-orders/${data.circulyOrderID}/tag`,
        {
          tag: "in risk review",
          tag_date: tagDate,
        }
      );
      console.log("Submission successful:", response.data);
      await updateDailyMovementSummary();
      navigate("/delivery-confirmation", { state: { deliveryData: data } });
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMsg(
        "Det oppstod dessverre en feil ved innsending av data, send oss en mail på hei@joule.no, så hjelper vi deg med bestilling av utlevering."
      );
    }
    setIsLoading(false); // Stop loading
  };

  // Determine if the submit button should be disabled
  const isSubmitDisabled =
    data.deliveryType === "nydalen"
      ? !data.deliveryDate
      : data.deliveryType === "wanda"
      ? !(data.deliveryDate && data.selectedTimeslot)
      : true;

  // Dynamically generating the button class
  const buttonClass = `w-32 tracking-wide font-bold rounded-lg py-2 px-6 inline-flex items-center ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed" // Styling for disabled state
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105" // Styling for active state
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg dato for utlevering
        </h2>
        <CalendarComp
          data={data}
          setData={setData}
          sourcePage={"DeliveryPage"}
        />
        {/* Display error message if present */}
        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isSubmitDisabled || isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Bestill utlevering"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPage;
