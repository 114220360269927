import React from "react";
import TopBar from "../components/TopBar";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import nbLocale from "date-fns/locale/nb";

const ServiceBookingConfirmationPage = () => {
  const location = useLocation();
  const bookingData = location.state?.bookingData || {};

  return (
    <div className="flex flex-col min-h-screen">
      <TopBar />
      <div className="bg-joule flex-grow flex flex-col sm:px-8 lg:px-8 z-100">
        <h2 className="my-6 text-center text-lg font-century-gothic text-jouletext">
          BEKREFTELSE PÅ BOOKING
        </h2>
        <div className="bg-white px-3 pt-2 pb-4 rounded-lg shadow-md mx-auto w-2/3 flex-grow mb-8">
          <h2 className="text-center font-century-gothic mb-4">
            BOOKINGDETALJER
          </h2>
          <div className="mb-2">
            <label className="font-bold text-gray-600">Type:</label>
            <p className="ml-2">{bookingData.type}</p>
          </div>
          <div className="mb-2">
            <label className="font-bold text-gray-600">
              Dato for innlevering:
            </label>
            <p className="ml-2">
              {bookingData.bookingDate
                ? format(new Date(bookingData.bookingDate), "PP", {
                    locale: nbLocale,
                  })
                : "Ikke tilgjengelig"}
            </p>
          </div>
          <div className="mb-2">
            <label className="font-bold text-gray-600">Lokasjon:</label>
            <p className="ml-2">Gjerdrums vei 11 </p>
          </div>
          <div className="mb-2">
            <label className="font-bold text-gray-600">Dine merknader:</label>
            <p className="ml-2">{bookingData.note}</p>
          </div>
          <div className="mt-8">
            <h2 className="text-center font-century-gothic mb-4">NESTE STEG</h2>
            <ul className="list-disc pl-5 mt-2 space-y-4">
              <li>
                Vi ønsker deg velkommen til vårt servicesenter i Gjerdrums vei
                11 i Nydalen mellom kl. 10:00-17:00 på den valgte datoen
              </li>
            </ul>
            <p className="mt-10">
              Ønsker du å endre bookingen? Ta kontakt på hei@joule.no
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingConfirmationPage;
