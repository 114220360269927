export const validateField = (field, value) => {
  if (field === "email") {
    return !/^\S+@\S+\.\S+$/.test(value); // Return true if email is not valid
  } else if (field === "number") {
    return !/^\+[0-9]{10}$/.test(value); // Return true if phone number is not valid
  } else if (field === "serialNumber") {
    return !/^[0-9]{1,4}$/.test(value); // Return true if number is not valid
  }
  return !value; // Return true if value is empty
};
