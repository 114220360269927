import React from "react";

const TopBar = () => {
  return (
    <div className="flex w-full h-full justify-center items-center py-8">
      <img
        src={process.env.PUBLIC_URL + "/pictures/joule_logo.png "}
        alt="logo"
        className="object-cover max-h-28 sm:max-h-48 w-auto"
      />
    </div>
  );
};

export default TopBar;
