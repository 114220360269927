import React from "react";
import { useLocation } from "react-router-dom";
import TopBar from "../components/TopBar";

const DeliveryConfirmationPage = () => {
  const { state } = useLocation();
  const deliveryData = state?.deliveryData;

  // Function to return time slot or opening time based on deliveryType
  const renderTimeSlotOrOpeningTime = () => {
    if (deliveryData?.deliveryType === "nydalen") {
      return "Åpningstid: 10:00 - 17:00";
    } else if (deliveryData?.deliveryType === "wanda") {
      return deliveryData?.selectedTimeslot
        ? `${deliveryData.selectedTimeslot.from.slice(
            0,
            2
          )}:${deliveryData.selectedTimeslot.from.slice(
            2
          )} - ${deliveryData.selectedTimeslot.to.slice(
            0,
            2
          )}:${deliveryData.selectedTimeslot.to.slice(2)}`
        : "Not Specified";
    }
    return "Not Specified";
  };

  // Function to return delivery type text based on deliveryType
  const renderDeliveryTypeText = () => {
    if (deliveryData?.deliveryType === "nydalen") {
      return "Utlevering på servicesenter";
    } else if (deliveryData?.deliveryType === "wanda") {
      return "Levering på dør";
    }
    return "Unknown Delivery Type";
  };

  // Function to return the bottom text based on deliveryType
  const renderBottomText = () => {
    if (deliveryData?.deliveryType === "nydalen") {
      return "Vennligst kontakt oss om du trenger å gjøre endringer til din utlevering.";
    } else if (deliveryData?.deliveryType === "wanda") {
      return "Vennligst kontakt oss om du trenger å gjøre endringer til din levering.";
    }
    return "If you need to make any changes to your booking, please contact us.";
  };

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center items-center px-4 lg:px-8">
        <h2 className="text-2xl font-semibold text-center my-4 text-jouletext">
          Utleveringsbekreftelse
        </h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <p className="text-lg mb-2">Takk for din bestilling!</p>
          <p>Din bestilling er planlagt for:</p>
          <div className="mb-4">
            <p>
              <strong>Dato:</strong> {deliveryData?.deliveryDate}
            </p>
            <p>
              <strong>Tidspunkt:</strong> {renderTimeSlotOrOpeningTime()}
            </p>
            <p>
              <strong>Leveringstype:</strong> {renderDeliveryTypeText()}
            </p>
          </div>
          <p>{renderBottomText()}</p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryConfirmationPage;
