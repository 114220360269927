import React from "react";
import { useLocation } from "react-router-dom";
import TopBar from "../components/TopBar";

const ReturnConfirmationPage = () => {
  const { state } = useLocation();
  const returnData = state?.returnData;

  // Function to return time slot or opening time based on deliveryType
  const renderTimeSlotOrOpeningTime = () => {
    if (returnData?.deliveryType === "nydalen") {
      return "Åpningstid: 10:00 - 17:00";
    } else if (returnData?.deliveryType === "wanda") {
      return returnData?.selectedTimeslot
        ? `${returnData.selectedTimeslot.from.slice(
            0,
            2
          )}:${returnData.selectedTimeslot.from.slice(
            2
          )} - ${returnData.selectedTimeslot.to.slice(
            0,
            2
          )}:${returnData.selectedTimeslot.to.slice(2)}`
        : "Not Specified";
    }
    return "Not Specified";
  };

  // Function to return delivery type text based on deliveryType
  const renderDeliveryTypeText = () => {
    if (returnData?.deliveryType === "nydalen") {
      return "Tilbakelevering på servicesenteret i Gjerdrums vei 11";
    } else if (returnData?.deliveryType === "wanda") {
      return "Henting på dør av Wanda.space";
    }
    return "Unknown Delivery Type";
  };

  // Function to return the bottom text based on deliveryType
  const renderBottomText = () => {
    if (returnData?.deliveryType === "nydalen") {
      return "Vennligst kontakt oss på hei@joule om du ønsker å gjøre endringer til din tilbakelevering.";
    } else if (returnData?.deliveryType === "wanda") {
      return "Vår logistikkleverandør Wanda.space vil snart sende en bekreftelse på henting. Du vil også motta en påminnelse dagen før henting, med et 2 timers tidsintervall for når de kommer.";
    }
    return "If you need to make any changes to your booking, please contact us.";
  };

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center items-center px-4 lg:px-8">
        <h2 className="text-2xl font-semibold text-center mt-4 mb-8 text-jouletext">
          Tilbakeleveringsbekreftelse
        </h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <p className="text-lg mb-4">Takk for at du syklet med Joule!</p>
          <p>Din tilbakelevering er planlagt:</p>
          <div className="my-4">
            <p>
              <strong>Dato:</strong> {returnData?.deliveryDate}
            </p>
            <p>
              <strong>Tidspunkt:</strong> {renderTimeSlotOrOpeningTime()}
            </p>
            <p>
              <strong>Metode:</strong> {renderDeliveryTypeText()}
            </p>
          </div>
          <p>{renderBottomText()}</p>
        </div>
      </div>
    </div>
  );
};

export default ReturnConfirmationPage;
