import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import PersonaliaComp from "../components/PersonaliaComp";
import TypeOfBooking from "../components/TypeOfBooking";
import { validateField } from "../components/formUtils";
import axios from "axios";

const ServiceBookingPage = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const [data, setData] = useState({
    type: "Service",
    bookingDate: "",
    serialNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    number: "+47",
    note: "",
    bookingStatus: "new",
  });

  const isFormValid = () => {
    return (
      !validateField("serialNumber", data.serialNumber) &&
      !validateField("firstName", data.firstName) &&
      !validateField("lastName", data.lastName) &&
      !validateField("email", data.email) &&
      !validateField("number", data.number) &&
      !validateField("bookingDate", data.bookingDate)
    );
  };

  const RegisterBooking = async (e) => {
    const {
      type,
      bookingDate,
      serialNumber,
      firstName,
      lastName,
      email,
      number,
      note,
      bookingStatus,
    } = data;
    e.preventDefault();

    console.log("About to make axios post request..."); // Debug line

    try {
      await axios.post(
        "/api/register",
        {
          type,
          bookingDate,
          serialNumber,
          firstName,
          lastName,
          email,
          number,
          note,
          bookingStatus,
        },
        { timeout: 5000 }
      ); // adding a timeout of 5 seconds
      navigate("/booking-confirmation", { state: { bookingData: data } });
    } catch (error) {
      setErrorMsg("Det oppstod en feil ved registrering av bookingen."); // Update the error message for the user
      console.error("Registration failed", error); // Keep this for actual error logging in development/production
    }
  };

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 text-center text-lg font-century-gothic text-jouletext">
          VELG ØNSKET TJENESTE
        </h2>
        <TypeOfBooking data={data} setData={setData} />
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          VELG DATO FOR INNLEVERING
        </h2>
        <CalendarComp
          data={data}
          setData={setData}
          sourcePage={"ServiceBookingPage"}
        />
        <h2 className="my-6 text-center text-lg font-century-gothic text-jouletext">
          LEGG INN INFORMASJON
        </h2>
        <PersonaliaComp data={data} setData={setData} />
        {errorMsg && <p className="text-red-600 text-center">{errorMsg}</p>}
        <div className="flex my-5 justify-center">
          <button
            onClick={(e) => {
              if (isFormValid()) {
                RegisterBooking(e);
              } else {
                setErrorMsg(
                  "Sjekk at du har lagt inn all informasjon, alle felt bortsett fra kommentar må fylles ut. Telefonnummer skal inkludere landkode først uten mellomrom, for eksempel +4792079622."
                );
              }
            }}
            className="w-32 bg-white tracking-wide text-black font-bold rounded-lg border-black hover:bg-gray-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
          >
            <span className="mx-auto">Send inn</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingPage;
